import React, {useState, FC, useEffect, useReducer} from 'react';
import {Form, FormItem, Input} from '../../components/form';
import LoginImage from '../../../static/login_img.png';
import {Translate} from '../../components/translations';
import {UserResetPasswordState, UserResetPasswordProps, UserResetPasswordMutationData} from '../../types';
import Button from '../../components/form/button';
import Layout from '../../components/layout/';
import Section from '../../components/section';
import {useMutation} from '@apollo/client';
import {USER_RESET_PASSWORD} from '../../graphql/mutations';
import {navigate} from 'gatsby';
import Message from '../../components/messages';

import {VALID_PASSWORD_RE} from '../../helpers/constants';
import {Modal} from '../../components/modal';
import {getRawCookie} from '../../helpers';

import {eyeIcon} from '../../images/svgIcons';

import './styles.scss';

const ResetPassword: FC<UserResetPasswordProps> = ({token}) => {
  const [resetMutation, {loading}] = useMutation<UserResetPasswordMutationData>(USER_RESET_PASSWORD);
  const [formData, setFormData] = useState<UserResetPasswordState>({
    password: '',
    repeatPassword: '',
    error: false,
    errorMessage: '',
    verifyCode: '',
  });
  const [showPassword, setShowPassword] = useReducer((val: boolean) => !val, false);
  const [showRepeatPassword, setShowRepeatPassword] = useReducer((val: boolean) => !val, false);
  const [passwordMessage, setPasswordMessage] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [limitExceed, setLimitExceed] = useState(false);
  const fieldChangeHandler = (event: React.SyntheticEvent): void => {
    const target = event.target as HTMLInputElement;
    const {value, name} = target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submitHandler = async (): Promise<void> => {
    if (formData.password === formData.repeatPassword) {
      setFormData({
        ...formData,
        error: false,
        errorMessage: 'Passwords are not the same',
      });
      if (formData.password.length > 64) {
        setLimitExceed(true);
        setPasswordMessage(false);
        return;
      }
      if (VALID_PASSWORD_RE.test(formData.password)) {
        setPasswordMessage(false);
        setLimitExceed(false);
      } else {
        setPasswordMessage(true);
        setLimitExceed(false);
        return;
      }
    }

    if (formData.repeatPassword !== formData.password) {
      setPasswordMessage(false);
      setLimitExceed(false);
      setFormData({
        ...formData,
        error: true,
        errorMessage: 'Passwords are not the same',
      });
    } else {
      const {data} = await resetMutation({
        variables: {
          password: formData.password,
          verifyCode: token || 'token',
          device_key: getRawCookie('device_key'),
        },
      });
      if (data) {
        const {
          UserOps: {
            resetPassword: {error, message},
          },
        } = data;
        if (error) {
          setFormData({
            ...formData,
            error: true,
            errorMessage: message === 'SAME_PASSWORD' ? 'Same password' : message,
          });
        } else {
          setModalOpened(true);
          setTimeout(() => {
            navigate('/login', {state: {goBack: true}});
          }, 5000);
        }
      }
    }
  };

  useEffect(() => {
    const el1 = document.getElementById('software-integrations');
    const el2 = document.getElementById('integration-mobile');
    const el3 = document.getElementsByClassName('integration-item')[0];
    const el4 = document.getElementsByClassName('promo_item')[0];
    el1 && (el1.className = 'login_area light_section_area ');
    el2 && (el2.className = 'container');
    el3 && (el3.className = 'row');
    el4 && (el4.className = 'container');
  }, []);

  return (
    <Layout>
      <Section className="login_area" pagehero lgPd>
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="login_info">
                <h2 className="f_p f_700 f_size_40 t_color3 mb_20">
                  <Translate name="PASSWORD_RESET_PAGE_TITLE" />
                </h2>
                <Modal
                  isOpen={modalOpened}
                  modalToggle={() => {}}
                  title="Password Reset"
                  modalFooter={
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => navigate('/login', {state: {goBack: true}})}
                    >
                      <Translate name="PASSWORD_RESET_SUCCESS_MODAL_BTN" />
                    </button>
                  }
                >
                  <Translate name="PASSWORD_RESET_SUCCESS_MESSAGE" />
                </Modal>
                <Form className="mt_60 login-form" onSubmit={submitHandler}>
                  <FormItem>
                    <label className="f_p text_c d-flex f_400">
                      <Translate name="PASSWORD" />
                      <span className="required_icon">*</span>
                    </label>
                    <Translate name="PASSWORD">
                      {(text: string) => (
                        <div className="password-div">
                          <Input
                            required
                            placeholder={text}
                            type={!showPassword ? 'password' : 'text'}
                            name="password"
                            onChange={fieldChangeHandler}
                            value={formData.password}
                            className="password"
                          />
                          <span
                            className={`eye-icon-password ${showPassword ? ' active' : ''}`}
                            onClick={setShowPassword}
                          >
                            {eyeIcon}
                          </span>
                        </div>
                      )}
                    </Translate>
                  </FormItem>
                  <FormItem>
                    <label className="f_p text_c f_400 d-flex">
                      <Translate name="REPEAT_PASSWORD" />
                      <span className="required_icon">*</span>
                    </label>
                    <Translate name="REPEAT_PASSWORD">
                      {(text: string) => (
                        <div className="password-div">
                          <Input
                            required
                            type={!showRepeatPassword ? 'password' : 'text'}
                            name="repeatPassword"
                            onChange={fieldChangeHandler}
                            value={formData.repeatPassword}
                            placeholder={text}
                            className="password"
                          />
                          <span
                            className={`eye-icon-password ${showRepeatPassword ? ' active' : ''}`}
                            onClick={setShowRepeatPassword}
                          >
                            {eyeIcon}
                          </span>
                        </div>
                      )}
                    </Translate>
                  </FormItem>

                  {passwordMessage && (
                    <Message
                      type="error"
                      text={
                        'Your password should consist of at least 8 characters including at least 1 digit, 1 capital letter and at least 1 special character'
                      }
                    />
                  )}
                  {limitExceed && <Message type="error" text="Maximum allowed password length is 64 characters." />}
                  {formData.error && (
                    <div className="mt-2">
                      <Message
                        type="error"
                        text={formData.errorMessage} // <Translate name={formData.errorMessage} />
                      />
                    </div>
                  )}
                  <Button btnText="Reset Password" className="btn_three" loading={loading}></Button>
                </Form>
              </div>
            </div>
            <div className="col-lg-4 d-flex align-items-center img-wrapper">
              <img src={LoginImage} alt="" />
            </div>
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default ResetPassword;
