import React, { FC, FormEvent, memo, useCallback } from "react";
import "./styles.scss";

interface IAppProps {
  value: string;
  onChange: any;
  placeholder?: string;
  name?: string;
  rows?: number;
  className?: string;
  onBlur?: any;
  onFocus?: any;
  disableResize?: boolean;
  error?: boolean;
  id?: string;
}

export const TextArea: FC<IAppProps> = ({
  value,
  onChange,
  placeholder,
  name,
  rows,
  className,
  onBlur,
  onFocus,
  disableResize,
  error,
  id,
}) => {
  const fieldChangeIndex = useCallback(
    (e: FormEvent) => {
      onChange(e);
    },
    [onChange]
  );
  const handleBlur = useCallback(
    (e: any) => {
      onBlur && onBlur(e);
    },
    [onBlur]
  );
  const handleFocus = useCallback(
    (e: any) => {
      onFocus && onFocus(e);
    },
    [onFocus]
  );
  return (
    <>
      <textarea
        id={id ? id : ""}
        className={`textarea ${className ? className : ""} ${
          disableResize ? "disable-resize" : ""
        } ${error ? "error-border" : ""}`}
        name={name}
        placeholder={placeholder}
        value={value}
        rows={rows || 6}
        onChange={fieldChangeIndex}
        onBlur={handleBlur}
        onFocus={handleFocus}
        maxLength={2000}
      />
    </>
  );
};

export default memo(TextArea);
